.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

header {
  margin-bottom: 20px;
}

header input {
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

header button {
  padding: 10px 20px;
  font-size: 16px;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-item {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  text-align: center;
  max-width: 200px;
}

.card-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}